import React from 'react';
import { Link } from 'react-router-dom';
import './card.scss';    

class Card extends React.Component {
  constructor() {
    super();

  }

  render() {

      return (
          
            <div  className="card">
                <div className="bookDetail" >
                  <div className={this.props.lang}>
                  <div className="image">
                                <img src={this.props.img} alt={this.props.title}/>

                                </div>
                                <span className="titleCard">
                                    {this.props.title}
                                </span>
                                <span className="descriptionCard">
                                    {this.props.description}
                                </span>

                                <a target="_blank" href={this.props.link} class="bookLink">
              
                                    <i class="icon-left-arrow"></i>
                                    </a>
                  </div>

                                                
                </div>

            </div>

       );

  }
 
}
export default Card;
