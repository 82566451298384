import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import menu from './reducers/menu';
import common from './reducers/common';
import experience from './reducers/experience';
import scientific from './reducers/scientific';
import certificates from './reducers/certificates';
import books from './reducers/books'
import skills from './reducers/skills'

export default combineReducers({
  menu,
  common,
  experience,
  scientific,
  certificates,
  books,
  skills,
  router: routerReducer
});
