import React from 'react';
import { Link } from 'react-router-dom';
import './header.scss';

class Header extends React.Component {

  constructor() {
    super();
    this.state = {dataHeader : {
        loacation:"EN",
        route:"/en"
    }}
  }
  componentDidMount() {
    if(this.props.lang=="en"){
        this.state.dataHeader = {
          loacation:"FA",
          route:"/"
    
        }
        this.forceUpdate();
        document.getElementById("mainTitle").innerHTML ="Mohammad Mehdi Motevali .PHD"
            }

  }

  render() {
    return (
      <nav>
        <ul>
          <li class={"lang "+ this.props.lang }>
            <a href={this.state.dataHeader.route}>
            <div>
              <span className="circle"></span>
              <span className="text">
                  {this.state.dataHeader.loacation}
              </span>
            </div>
            </a>

          </li>
          <li class="linkedin">
            <a href="https://www.linkedin.com/in/mohammad-mahdi-motevali-phd-7392002a/">
            <div class="socialMedia">
              
              <i class="icon-linkedin"></i>
            </div>
            </a>

            
          </li>

          <li class="email">

            <a href="#">
            <span><a href = "mailto: DBA.motevali@gmail.com">DBA.motevali@gmail.com</a></span>

            <div class="socialMedia">
              <i class="icon-envelope "></i>
            </div>
            </a>
            
          </li>
         
        </ul>
      </nav>
    );
  }
}

export default Header;
