import React from 'react';
import { connect } from 'react-redux';
import Navigation from './Navigation/Navigation'; 
import './firstLook.scss';
const Promise = global.Promise;

const mapStateToProps = state => {
  return {
    lang:state.router.location.pathname.replace('/','')
  }
};

const mapDispatchToProps = dispatch => ({

});

class FirstLook extends React.Component {
  componentWillMount() {
  }

  componentWillUnmount() {
  }

  render() {
    if(this.props.lang==''){
      return (
        <div id="firstLook" className="firstLook fa" >
              <img src="/images/dr.jpg" alt="mohammad mehdi motevali"></img>
              <Navigation lang="fa" />
              <div class="name">
                  <span>دکتر</span>
                  <h1>محمد مهدی متولی</h1>
              </div>
        </div>
    );
  }else if(this.props.lang=='en'){
    return (
      <div id="firstLook" className="firstLook en" >
      <img src="/images/dr-en.jpg" alt="mohammad mehdi motevali"></img>
      <Navigation lang="en" />
      <div class="name">
          <span>Mohammad Mehdi</span>
          <h1>Motevali .PHD</h1>
      </div> 
  </div>);}}
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstLook);
