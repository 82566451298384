import React from 'react';
import { Link } from 'react-router-dom';
import agent from '../../../agent';
import { connect } from 'react-redux';
import './history.scss';    

class History extends React.Component {
  constructor() {
    super();
    this.state = {isOpen : {}}
  }

  componentDidMount() {
    this.state.isOpen['i'+0+'i'+0+'']= true;

    this.forceUpdate();

  }

showOrHide = (index,parentIndex) => () => {
  if(!this.state.isOpen['i'+index+'i'+parentIndex+'']){
    this.state.isOpen['i'+index+'i'+parentIndex+'']= true;

  }

  else{
    this.state.isOpen['i'+index+'i'+parentIndex+'']= false;

  }
  this.forceUpdate();


};
  render() {

      return (
            <div className="history-block">
                {
              this.props.data.map((item,parentIndex) => {
                return (
                  <div>
                  <p className="title"> {item.title}
                  <span className="line"></span>
                  </p>
                {
                  item.list.map((subData,index) => {
                    return (
                      <div>
                        <span className={` ${this.state.isOpen['i'+index+'i'+parentIndex+'']? 'option stroke' : 'option filled'}`}  onClick={this.showOrHide(index,parentIndex)} key={index} ></span>
                        <label className={` ${this.state.isOpen['i'+index+'i'+parentIndex+'']? 'hide-title' : 'show-title'}`} >{subData.subject}</label>
                        <div className={` ${this.state.isOpen['i'+index+'i'+parentIndex+'']? 'detail  show' : 'detail hide'}`}>
                        <div>
                        {

                              subData.dataList.map((detail,index) => {
                                return (
                                     <span>
                                      {detail.text}
                                      </span>
                                )
                            })
                          }
                        </div>
                        <div>
                        <img src={subData.icon} alt="history"></img>
                        </div>
                           
                        </div>
                        
                      </div>
                    )

                  })
                  

                }
                  </div>
                )
              })
            }
         </div>   
       );

  }






 
}
export default History;
