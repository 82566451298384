import React from 'react';
import { Link } from 'react-router-dom';
import agent from '../../../../agent';
import { connect } from 'react-redux';
import {MENU} from '../../../../constants/actionTypes';
import './navigation.scss';
    


const mapStateToProps = state => {
    return {
        menuList: state.menu.menuData,
        menuLoad:state.menu.menuLoad
    }};


const mapDispatchToProps = dispatch => ({
    menu : value =>{
        agent.navigation.getMenu(value).then(response=>dispatch({type:MENU , payload:response.body}))
    }
        
})
class Navigation extends React.Component {
    componentWillMount() {
          return this.props.menu(this.props.lang);        
      }

  render() {
      if(this.props.menuLoad){
        return (
            <ul className={"nav-list "+this.props.lang}>
            {
              this.props.menuList.map(item => {
                return (
                  <li className="tag-default tag-pill tag-outline" >
                    <a href={item.route}> {item.name}</a>
                    
                  </li>
                )
              })
            }
          </ul>   
           );
      }

      return (
            <ul className="tag-list">
         </ul>   
       );

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
