import React from 'react';
import { Link } from 'react-router-dom';
import agent from '../../../agent';
import { connect } from 'react-redux';
import {EXPERIENCE} from '../../../constants/actionTypes';
import './experiences.scss';
import History from '../History/History'

const mapStateToProps = state => {
    return {
        experienceList: state.experience.experienceData,
        experienceLoad:state.experience.experienceLoad,
        lang:state.router.location.pathname.replace('/','')
    }};


const mapDispatchToProps = dispatch => ({
    experience : value =>{
        agent.experience.getExperience(value).then(response=>dispatch({type:EXPERIENCE , payload:response.body}))
    }
        
})
class Experiences extends React.Component {
    componentWillMount() {
          return this.props.experience(this.props.lang);        
      }

  render() {
      if(this.props.experienceLoad){
           if(this.props.lang){

           }
        return (
        <div id="experiences" className={"exprience " +this.props.lang}>
            <div class="mainBlock" >
                <h2>
                    {this.props.experienceList.title}
                </h2>
                <p>
                    {this.props.experienceList.description}
                </p>
             </div> 

        

            <History data={this.props.experienceList.history} lang={this.props.lang}/>

        </div>

        
           );
      }

      return (
            <ul className="tag-list">
                
         </ul>   
       );

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Experiences);
