import React from 'react';
// import agent from '../../agent';
import { connect } from 'react-redux';
import './index.scss';
import Header from './Header/Header';
import FirstLook from './FirstLook/index';
import Experiences from './Experiences/Experiences';
import Scientific from './Scientific/Scientific';
import Certificates from './Certificates/Certificates';
import Books from './Book/Books';
import Skills from './Skills/Skills';
import Footer from './Footer/Footer';


const Promise = global.Promise;

const mapStateToProps = state => {
  return {
    lang:state.router.location.pathname.replace('/','')
}
};

const mapDispatchToProps = dispatch => ({

});

class Landing extends React.Component {
  constructor() {
    super();
    this.state = {dataMain : {
        copy:"تمامی حقوق برای دکتر محمد مهدی متولی محفوظ می باشد 2020"
    }}
  }
  componentDidMount() {
    if(this.props.lang=="en"){
      this.state.dataMain = {
        copy:"copyright 2020 Mohammad Mehdi Motevali ,PHD"
  
      }
      this.forceUpdate();
          }
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div className={this.props.lang}>
              <Header  lang={this.props.lang} />
              <FirstLook />
              <Experiences />
              <Certificates />
              <Scientific />
              <Books />
              <Skills />
              <Footer lang={this.props.lang} />
              <span className="copy">{this.state.dataMain.copy} </span>
      </div>   

    );



  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
