export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const ASYNC_END = 'ASYNC_END';
export const ASYNC_START = 'ASYNC_START';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const MENU = 'MENU';
export const ARTICLE_PAGE_UNLOADED = 'ARTICLE_PAGE_UNLOADED';
export const EXPERIENCE = 'EXPERIENCE';
export const SCIENTIFIC = 'SCIENTIFIC';
export const CERTIFICATE = 'CERTIFICATE';
export const BOOK = 'BOOK';
export const SKILLS = 'SKILLS';






