import React from 'react';
import { Link } from 'react-router-dom';
import agent from '../../../agent';
import { connect } from 'react-redux';
import {SKILLS} from '../../../constants/actionTypes';
import './skills.scss';

const mapStateToProps = state => {
    return {
        skillsList: state.skills.skillsData,
        skillsLoad:state.skills.skillsLoad,
        lang:state.router.location.pathname.replace('/','')

    }};


const mapDispatchToProps = dispatch => ({
    skills : value =>{
        agent.skills.getskills(value).then(response=>dispatch({type:SKILLS , payload:response.body}))
    }
        
})
class Skills extends React.Component {
    componentWillMount() {
          return this.props.skills(this.props.lang);        
      }

  render() {
      if(this.props.skillsLoad){
        return (
          
        <div id="skills" class={"skills "+this.props.lang}>
            <div class="mainBlock" >
                <h2>
                    {this.props.skillsList.title}
                </h2>
                <p>
                    {this.props.skillsList.description}
                </p>
             </div> 

             
                 <div className="skillList">
                     {
                        this.props.skillsList.skills.map(item => {
                            return (
                                <div className="skillItem">
                                    <p className="title"> {item.type}
                                    <span className="line"></span>
                                    </p>
                                    {
                                        item.data.map(detail => {
                                            return (
                                                <div className="item">
                                                    <span>
                                                        {detail.title}
                                                    </span> 
                                                    <i className={detail.imgClass}></i>
                                                </div>
    
                                            )})
                                    }
                                </div>


                                
                            )})
                     }

            
                 </div>

            

        </div>
            
           );
      }

      return (
            <ul className="tag-list">
                <p>loading...</p>
         </ul>   
       );

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Skills);
