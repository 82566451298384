import React from 'react';
import { Link } from 'react-router-dom';
import agent from '../../../agent';
import { connect } from 'react-redux';
import {BOOK} from '../../../constants/actionTypes';
import Card from '../cards/card'
import './books.scss';

const mapStateToProps = state => {
    return {
        booksList: state.books.booksData,
        booksLoad:state.books.booksLoad,
        lang:state.router.location.pathname.replace('/','')
    }};


const mapDispatchToProps = dispatch => ({
    books : value =>{
        agent.books.getBooks(value).then(response=>dispatch({type:BOOK , payload:response.body}))
    }
        
})
class Books extends React.Component {
    componentWillMount() {
          return this.props.books(this.props.lang);        
      }

  render() {
    if(this.props.booksLoad){
        return (
            <div id="books" className={"book "+this.props.lang}>
                    {
                    <div className="mainBlock">
                        <h2>
                            {this.props.booksList.title}
                        </h2>
                        <p>
                            {this.props.booksList.description}
                        </p>
                    </div> 
                    }  

                    <div class="bookList">
                    {
                    this.props.booksList.books.map(item => {
                        return (
                            <Card title={item.title} link={item.link}  img={item.img} description={item.description} lang={item.lang}/>

                        )
                    })
                    }
                    
                </div>        
            </div>       
           );
      }
      return (
        <div className="certificate">   
        </div>       
       );

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Books);
