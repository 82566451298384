import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.PUBLIC_URL+'/rest/api/';

const encode = encodeURIComponent;
const responseBody = res =>res.body.body ;


const requests = {
  del: url =>
    superagent.del(`${API_ROOT}${url}`).then(responseBody),
  get: url =>
    superagent.get(`${API_ROOT}${url}`),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).then(responseBody),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).then(responseBody)
};
const navigation = {
  getMenu: (lang) =>{
    let url='';
    if(lang=="en"){
      url="/en/"
    }
    return requests.get(url+'menu.json');
  }
}
const experience = {
  getExperience: (lang) =>{
    return requests.get(`${lang}/experience.json`);
  }
}
const scientific = {
  getScientific: (lang) =>{
    return requests.get(`${lang}/scientific.json`);
  }
}
const certificates = {
  getCertificates: (lang) =>{
    return requests.get(`${lang}/certificates.json`);
  }
}
const books = {
  getBooks: (lang) =>{
    return requests.get(`${lang}/books.json`);
  }
}
const skills = {
  getskills: (lang) =>{
    return requests.get(`${lang}/skills.json`);
  }
}





export default {
  navigation,
  experience,
  scientific,
  certificates,
  skills,
  books
};
