import React from 'react';
import { Link } from 'react-router-dom';
import agent from '../../../agent';
import { connect } from 'react-redux';
import {CERTIFICATE} from '../../../constants/actionTypes';
import './certificates.scss';
import History from '../History/History'

const mapStateToProps = state => {
    return {
        certificatesist: state.certificates.certificatesData,
        certificatesLoad:state.certificates.certificatesLoad,
        lang:state.router.location.pathname.replace('/','')

    }};


const mapDispatchToProps = dispatch => ({
    certificates : value =>{
        agent.certificates.getCertificates(value).then(response=>dispatch({type:CERTIFICATE , payload:response.body}))
    }
        
})
class Certificates extends React.Component {
    componentWillMount() {
          return this.props.certificates(this.props.lang);        
      }

  render() {
      if(this.props.certificatesLoad){
        return (
            <div id="certificates" class={"certificate "+this.props.lang}>
                    {
                    <div class="mainBlock">
                        <h2>
                            {this.props.certificatesist.title}
                        </h2>
                        <p>
                            {this.props.certificatesist.description}
                        </p>
                    </div> 

                    }
                <ul class="certificateList">
                    {
                    this.props.certificatesist.detail.map(item => {
                        return (
                        <li className="tag-default tag-pill tag-outline" >
                            {item.title}
                        </li>
                        )
                    })
                    }
                    
                </ul>
            </div>
           
            
           );
      }

      return (
            <ul className="tag-list">
                <p>hyhiuu</p>
         </ul>   
       );

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Certificates);
