import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './footer.scss';    

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {dataFooter : {
        description:"دکتر محمد مهدی متولی",
        tabriz:"دانشگاه تبریز",
        shiraz:"دانشگاه شیراز",
        sazmanModiriyat:"سازمان مدیریت صنعتی",
        tehran:"دانشگاه تهران",
        azad:"دانشگاه آزاد",
        rayadadeh:"رایاداده",
        experiences:"تجربیات",
        certificates:"گواهینامه ها",
        history:"سوابق علمی",
        skills:"تجربیات",
        experiences:"مهارت ها",
        books:"کتاب ها و مقالات",
        img:"/images/en/logo.png"
    }}

  }
  componentDidMount() {
    if(this.props.lang=="en"){
        this.state.dataFooter = {
            description:"Mohammad Mahdi Motevali .PHD",
            tabriz:"University of Tabriz",
            shiraz:"University of Shiraz",
            sazmanModiriyat:"",
            tehran:"University of Tehran",
            azad:"Islamic Azad Univercity",
            rayadadeh:"Raya Big Data",
            experiences:"Experiences",
            certificates:"Certificates",
            history:"Academic Background",
            skills:"Skills",
            experiences:"Experiences",
            books:"Books and Papers",
            img:"/images/en/logo.png"
    
        }
        this.forceUpdate();

      }

  }

  render() {


      return (
            <div className={"footer "+this.props.lang}>


                <div className="block-1">
                    <img src={this.state.dataFooter.img} alt="logo"/>
                    <span>
                       {this.state.dataFooter.description}
                    </span>
                    <div class="google-plus">
                        <a href="https://www.linkedin.com/in/mohammad-mahdi-motevali-phd-7392002a/">
                            <div class="socialMedia">
                                <i class="icon-linkedin"></i>
                            </div>
                        </a>
                    </div>

                </div>
                <div className="block-2">
                    <a target="_blank" href="http://tabrizu.ac.ir/fa">{this.state.dataFooter.tabriz}</a>
                    <a target="_blank" href="https://shirazu.ac.ir/">{this.state.dataFooter.shiraz}</a>
                    <a target="_blank" href="http://www.imi.ir/Pages/home.aspx">{this.state.dataFooter.sazmanModiriyat}</a>
                    <a target="_blank" href="https://ut.ac.ir/fa">{this.state.dataFooter.tehran}</a>
                    <a target="_blank" href="https://www.iau.ac.ir/">{this.state.dataFooter.azad}</a>
                    <a target="_blank" href="http://rayabigdata.com/">{this.state.dataFooter.rayadadeh}</a>


                </div>
                <div className="block-3">
                    <a href="#experiences">{this.state.dataFooter.experiences}</a>
                    <a  href="#certificates">{this.state.dataFooter.certificates}</a>
                    <a  href="#history">{this.state.dataFooter.history}</a>
                    <a  href="#skills">{this.state.dataFooter.skills}</a>
                    <a  href="#books">{this.state.dataFooter.books}</a>

                </div>
                <div className="block-4">
                    <img src="/images/qr-code.png" alt="qr-code-information"/>
                </div>
            </div>
       );
  }
 
}
export default Footer;


